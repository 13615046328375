import { dispatchOpenUrl, hasReactNativePostMessage } from "./dispatchMessage";

export function registerGlobalLinkHandler() {
  function interceptClickEvent(e: any) {
    try {
      const target = e.target || e.srcElement;
      if (target?.tagName === "A") {
        const href = target.getAttribute("href");
        if (href && hasReactNativePostMessage()) {
          e.preventDefault();
          dispatchOpenUrl(href); // let the react native app open the link
        } else {
          console.info("no react native handler found, opening link as usual");
        }
      }
    } catch (err: any) {
      console.error(err);
    }
  }

  document.addEventListener("click", interceptClickEvent);
}
